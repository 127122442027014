.App {
  text-align: center;
}
.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 80px;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.App-intro {
  font-size: large;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.customDatePicker {
  /* font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px; */
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s;
}
.colorTransition {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.less-commonShading {
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.max900 {
  max-width: 900px !important;
}
.fontRegular {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 300;
}
.fontRegularTight {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
.fontMedium {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 600;
}
.fontHeavy {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 800;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-center {
  text-align: center;
}
.text-light {
  color: #717b81;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f4f7f9;
}
.appWrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  overflow: hidden;
}
.appPageWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
/* Spacing Styles */
.flex {
  display: flex;
}
@media screen and (max-width: 500px) {
  .flex-mob-off {
    flex-direction: column;
  }
}
.flex-reverse {
  flex-direction: row-reverse;
}
.flex-center-row {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.flex-center {
  align-items: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-between {
  justify-content: space-between;
}
.flex-around {
  justify-content: space-around;
}
.paddingBottom-4 {
  padding-bottom: 4px;
}
.paddingBottom-8 {
  padding-bottom: 8px;
}
.marginLeft-8 {
  margin-left: 8px;
}
.marginLeft-16 {
  margin-left: 16px;
}
.marginRight-8 {
  margin-right: 8px;
}
.marginRight-16 {
  margin-right: 16px;
}
.marginTop-4 {
  margin-top: 4px;
}
.marginTop-8 {
  margin-top: 8px;
}
.marginTop-16 {
  margin-top: 16px;
}
.mobileNo {
  display: block;
}
@media screen and (max-width: 400px) {
  .mobileNo {
    display: none;
  }
}
.mobileYes {
  display: none;
}
@media screen and (max-width: 399px) {
  .mobileYes {
    display: block;
  }
}
.header_wrapper {
  background-color: #fff;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 5;
  border-bottom: 1px solid #e7ecf1;
  min-height: 55px;
}
.sideNav {
  height: 100%;
  width: auto;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(106, 163, 216, 0.32);
  z-index: 20;
  display: flex;
  flex-direction: column;
}
.sideNav_titleWrapper {
  padding: 16px;
  display: inline-block;
  min-height: 55px;
}
.sideNav_innerWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.sideNav_section {
  padding-top: 8px;
  padding-bottom: 8px;
}
.sideNav-item-dot {
  opacity: 0;
  background-color: #48aef8;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.sideNav-item-icon {
  color: #5a5a6e;
  font-size: 16px;
  line-height: 18px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.sideNav-item-text {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #5a5a6e;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.sideNav-item {
  cursor: pointer;
}
.sideNav-item:hover .sideNav-item-dot,
.sideNav-item.sideNav-item--active .sideNav-item-dot {
  opacity: 1;
  background-color: #48aef8;
}
.sideNav-item:hover .sideNav-item-icon,
.sideNav-item.sideNav-item--active .sideNav-item-icon {
  color: #48aef8;
}
.sideNav-item:hover .sideNav-item-text,
.sideNav-item.sideNav-item--active .sideNav-item-text {
  color: #48aef8;
}
.hub-wrapperContainer {
  position: relative;
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
}
.hub-headerContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.hub-contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 32px;
  overflow-y: auto;
}
.patientContainer_poc {
  background-color: #fff;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 16px;
}
.patientContainer_poc-left {
  width: 200px;
}
@media screen and (max-width: 500px) {
  .patientContainer_poc-left {
    width: auto;
  }
}
.patientContainer_poc-right {
  width: 100%;
  border-left: 1px solid #ccc;
  padding-left: 16px;
}
@media screen and (max-width: 500px) {
  .patientContainer_poc-right {
    border-left: none;
    padding-left: 0px;
    margin-top: 12px;
  }
}
.patientContainer_poc-right-results {
  max-height: 260px;
  overflow-y: auto;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .patientContainer_poc-right-results {
    max-height: 80px;
  }
}
.patientContainer_poc_result {
  margin-top: 6px;
  border-top: solid 1px #ccc;
  display: flex;
  align-items: center;
  padding-top: 6px;
}
.patientContainer_poc_result:first-child {
  margin-top: 2px;
  border-top: none;
}
.loginHolder {
  background-color: #fff;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  max-width: 400px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonWrapperPoc {
  margin-top: 36px;
  background-color: #0083ee;
  padding: 8px 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.buttonText {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 600;
  color: #fff;
}
.patientEditContainer_poc {
  background-color: #fff;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 16px;
}
.button_off {
  background-color: #ccc;
}
.poc_modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
