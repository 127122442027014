.sidenav-panel {
  width: 200px;
  height: 100%;
  border-right: 1px solid #ebebeb;
}
.pageWrapper {
  width: 100%;
  padding: 32px;
}
.divider {
  border-top: 1px solid #ebebeb;
}

.react-datepicker-popper {
  z-index: 100 !important;
}
